/* eslint-disable eqeqeq */
import React from "react";
import RSVPForm from "./RSVP/RSVPForm";

const RSVP = ({ data, error, setData }) => {
  if (error) {
    console.log("Error fetching guest information: ", error.message);
  } else if (!data) {
    // API not responded yet, display nothing
    return "";
  } else if (data.hasResponded) {
    return (
      <div className="rsvp" data-aos="fade-up">
        <h2>RSVP</h2>
        <p>Thank you for your response!</p>
        {data.numGuests != 0 && (
          <p>We will send a confirmation to your email.</p>
        )}
        <p>
          If you need to change any response details, please reach out to
          Michael or Laura.
        </p>
      </div>
    );
  } else {
    // Happy path
    return (
      <div className="rsvp" data-aos="fade-up">
        <h2>RSVP</h2>
        <p className="replyDate">Kindly reply by Saturday 24 August 2024</p>
        <RSVPForm data={data} setData={setData} />
      </div>
    );
  }
};

export default RSVP;
