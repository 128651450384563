import React, { useState } from "react";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import { useInView } from "react-intersection-observer";

function Details({ onImageLoad, pageHasLoaded }) {
  const address = "Lot 2909 Lennard St, Herne Hill WA 6056";
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;

  //When the highlighted text reaches the right scroll point, trigger the animation - but only once the page has fully loaded
  const { ref: firstCatch } = useInView({
    onChange: (inView, entry) => {
      if (inView) {
        attemptToTriggerAnimation();
      }
    },
  });
  const { ref: secondCatch } = useInView({
    onChange: (inView, entry) => {
      if (inView) {
        attemptToTriggerAnimation();
      }
    },
  });
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const attemptToTriggerAnimation = () => {
    if (pageHasLoaded) {
      //will only set triggerAnimation after the page has loaded
      setTriggerAnimation(true);
    }
  };

  return (
    <div id="details-div" className="details" data-aos="fade-up">
      <RoughNotationGroup show={triggerAnimation}>
        <h2>The details</h2>
        <p>
          <RoughNotation
            type="underline"
            color="#efa59a"
            animationDelay="2000"
            order="1"
          >
            Saturday 5 October 2024
          </RoughNotation>
        </p>
        <p>
          <RoughNotation
            type="highlight"
            color="#f4ca88"
            animationDelay="3000"
            order="2"
          >
            Barrett Lane
          </RoughNotation>
          , Swan Valley, WA
        </p>
        <p>
          <a
            className="mapLink"
            href={googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Lot 2909 Lennard St, Herne Hill WA 6056
          </a>
        </p>
        <p>
          Arrive at{" "}
          <RoughNotation
            type="box"
            color="#efa59a"
            animationDelay="4000"
            order="3"
          >
            two-thirty in the afternoon
          </RoughNotation>{" "}
          for the ceremony at three.
        </p>
        <p>Followed by drinks, dinner, and dancing until late.</p>
        <div ref={firstCatch} />{" "}
        {/* when this is visible, triggers the highlighting to start */}
        <img src="Wedding_Map_transparent.png" alt="Location" onLoad={onImageLoad} />
        <div ref={secondCatch} />{" "}
      </RoughNotationGroup>
    </div>
  );
}

export default Details;
