import React from "react";

function PrintInvitation() {
  const handleClick = () => {
    const link = document.createElement("a");
    link.href = "invitation.pdf";
    link.download = "invitation.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="print-invitation" data-aos="fade-up">
      <h2>Print</h2>
      <p className="printInstructions">
        Print the invitation to put up on your fridge at home
      </p>
      <button onClick={handleClick} className="print-invitation-button">
        Print invitation
      </button>
    </div>
  );
}

export default PrintInvitation;
