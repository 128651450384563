import React, { useEffect, useRef, useState } from "react";
import TimelineObserver from "react-timeline-animation";
import confetti from "canvas-confetti";

const Timeline = ({ setObserver }) => {
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [message4, setMessage4] = useState("");
  const [message5, setMessage5] = useState("");
  const [message6, setMessage6] = useState("");
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [time3, setTime3] = useState("");
  const [time4, setTime4] = useState("");
  const [time5, setTime5] = useState("");
  const [time6, setTime6] = useState("");

  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  const timeline4 = useRef(null);
  const timeline5 = useRef(null);
  const timeline6 = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);
  const circle6 = useRef(null);

  const someCallback1 = () => {
    setTime1("2:30");
    setMessage1("Guests arrive at Barrett Lane");
  };

  const someCallback2 = () => {
    setTime2("3:00");
    setMessage2("Ceremony ❤️");

    //Throw confetti
    if (typeof OffscreenCanvas !== "undefined") {
      var scaleValue = 4; //Size of the confetti
      var redHeart = confetti.shapeFromText({ text: "❤️", scalar: scaleValue });
      var blueHeart = confetti.shapeFromText({
        text: "💙",
        scalar: scaleValue,
      });
      var greenHeart = confetti.shapeFromText({
        text: "💚",
        scalar: scaleValue,
      });
      var yellowHeart = confetti.shapeFromText({
        text: "💛",
        scalar: scaleValue,
      });
      var whiteHeart = confetti.shapeFromText({
        text: "🤍",
        scalar: scaleValue,
      });
      // var pinkHeart = confetti.shapeFromText({ text: "🩷", scalar: scaleValue });
      var purpleHeart = confetti.shapeFromText({
        text: "💜",
        scalar: scaleValue,
      });
      confetti({
        particleCount: 60,
        spread: 75,
        startVelocity: 35,
        origin: { y: 0.5 },
        scalar: scaleValue,
        ticks: 100,
        shapes: [
          redHeart,
          blueHeart,
          greenHeart,
          yellowHeart,
          whiteHeart,
          // pinkHeart,
          purpleHeart,
        ],
      });
    }
  };

  const someCallback3 = () => {
    setTime3("3:30");
    setMessage3("Cocktails, canapés and photographs");
  };

  const someCallback4 = () => {
    setTime4("5:30 (ish)");
    setMessage4("Dinner, cake and formalities");
  };

  const someCallback5 = () => {
    setTime5("later");
    setMessage5("Dancing 💃");
  };

  const someCallback6 = () => {
    setTime6("11:30");
    setMessage6("Goodbyes");
  };

  useEffect(() => {
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    setObserver(timeline4.current);
    setObserver(timeline5.current);
    setObserver(timeline6.current);
    setObserver(circle1.current, someCallback1);
    setObserver(circle2.current, someCallback2);
    setObserver(circle3.current, someCallback3);
    setObserver(circle4.current, someCallback4);
    setObserver(circle5.current, someCallback5);
    setObserver(circle6.current, someCallback6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="timelineWrapper">
      <div id="timeline1" ref={timeline1} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle1" ref={circle1} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time1}</div>
          <div className="timelineMessage">{message1}</div>
        </div>
      </div>
      <div id="timeline2" ref={timeline2} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle2" ref={circle2} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time2}</div>
          <div className="timelineMessage">{message2}</div>
        </div>
      </div>
      <div id="timeline3" ref={timeline3} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle3" ref={circle3} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time3}</div>
          <div className="timelineMessage">{message3}</div>
        </div>
      </div>
      <div id="timeline4" ref={timeline4} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle4" ref={circle4} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time4}</div>
          <div className="timelineMessage">{message4}</div>
        </div>
      </div>
      <div id="timeline5" ref={timeline5} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle5" ref={circle5} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time5}</div>
          <div className="timelineMessage">{message5}</div>
        </div>
      </div>
      <div id="timeline6" ref={timeline6} className="timeline" />
      <div className="timelineCircleWrapper">
        <div id="timelineCircle6" ref={circle6} className="timelineCircle" />
        <div className="timelineMessageDiv">
          <div className="timelineTime">{time6}</div>
          <div className="timelineMessage">{message6}</div>
        </div>
      </div>
    </div>
  );
};

function AdditionalInfo() {
  return (
    <div className="additional-info">
      <h2 data-aos="fade-up">Everything else</h2>
      <div data-aos="fade-up">
        <h3>When to RSVP by</h3>
        <p>Kindly reply by Saturday 24 August 2024.</p>
      </div>
      <div data-aos="fade-up">
        <h3>My details have changed</h3>
        <p>
          If you need to change any details from your RSVP, please contact
          Michael or Laura directly.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Transport</h3>
        <p>
          If you are planning on having a bevvy and not driving, we suggest
          booking a taxi to and from Barrett Lane. Ride shares are unreliable
          that far out in the Swan Valley. If you are driving, there is parking
          on the side of the road opposite the venue.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>A note on gifts</h3>
        <p>
          The most important thing to us is having you celebrate with us on our
          wedding day. If you wish to give a gift, we welcome contributions to
          our honeymoon via our wishing well on the day.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Timeline of the day</h3>
        <TimelineObserver
          initialColor="#cdc6e9"
          fillColor="#0a2463"
          // hasReverse="false"
          handleObserve={(setObserver) => (
            <Timeline className="timeline" setObserver={setObserver} />
          )}
        />
        <div className="timelineStubBottom" />
        <p />
      </div>
      <div data-aos="fade-up">
        <h3>Photography</h3>
        <p>
          We would love you to take photos and videos to capture the memories of
          the night! If you would like to share your photos with us, a location
          will be provided for you to upload them after the wedding.
          <br></br>
          <span className="photography">
            Please refrain from taking photos during the ceremony itself; we
            would like everyone to enjoy the celebration and let our
            photographer capture the moment.
          </span>
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Social media</h3>
        <p>
          We would like the chance to post any photos on social media first;
          please refrain from posting anything until after we have.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Additional guests</h3>
        <p>
          We kindly request that only the invited guests attend, as we are
          unable to accommodate plus ones or children. Thank you for your
          understanding.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Menu</h3>
        <p>
          We will be serving a variety of dishes in a family-banquet style, so
          everyone can help themselves to a little of everything from the middle
          of the table. For dessert, we will be offering Michael and Laura's
          favourite, honeycake.
          <br></br>
          <span className="menu">
            If you have any specific dietary requirements or allergies, please
            mention them when filling out your RSVP, or feel free to contact
            Michael or Laura directly.
          </span>
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Drinks</h3>
        <p>
          A selection of beer, wine, and champagne will be provided throughout
          the event. After the ceremony, guests can choose a cocktail of either
          Michael's or Laura's favourite. There will also be a variety of
          non-alcoholic beverages available.
        </p>
      </div>
      <div data-aos="fade-up">
        <h3>Weather</h3>
        <p>
          If the weather is pleasant (fingers crossed!), we will dine outdoors
          on the lawn to enjoy the spring air. However, if the weather is
          unfavourable, we will move the event indoors to the main hall.
        </p>
      </div>
    </div>
  );
}

export default AdditionalInfo;
