import React from "react";

function WhatToWear() {
  return (
    <div className="what-to-wear" data-aos="fade-up">
      <h2>What to wear</h2>
      <p>Dress formal / Dress to impress!</p>
      <p>
        We'd love for you to wear a suit & tie in any colour, evening gown, midi or
        knee-length cocktail dress. If you like a theme, think fancy garden
        party! 
      </p>
      <p>
        Note: If the weather is pleasant, the ceremony, cocktails and dinner
        will be outside on the lawn. However, if it looks like rain we will head indoors to the main hall.
        If it looks a little chilly you may want to bring a scarf or jacket. 
      </p>
    </div>
  );
}

export default WhatToWear;
