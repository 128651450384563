import confetti from "canvas-confetti";

const ThrowConfettiSubmitted = () => {
  if (typeof OffscreenCanvas !== "undefined") {
    var scaleValue = 4; //Size of the confetti
    var redHeart = confetti.shapeFromText({ text: "❤️", scalar: scaleValue });
    var blueHeart = confetti.shapeFromText({ text: "💙", scalar: scaleValue });
    var greenHeart = confetti.shapeFromText({ text: "💚", scalar: scaleValue });
    var yellowHeart = confetti.shapeFromText({
      text: "💛",
      scalar: scaleValue,
    });
    var whiteHeart = confetti.shapeFromText({ text: "🤍", scalar: scaleValue });
    // var pinkHeart = confetti.shapeFromText({ text: "🩷", scalar: scaleValue });
    var purpleHeart = confetti.shapeFromText({
      text: "💜",
      scalar: scaleValue,
    });
    confetti({
      particleCount: 150,
      spread: 75,
      startVelocity: 60,
      origin: { y: 0.8 },
      scalar: scaleValue,
      ticks: 300,
      shapes: [
        redHeart,
        blueHeart,
        greenHeart,
        yellowHeart,
        whiteHeart,
        // pinkHeart,
        purpleHeart,
      ],
    });
  }
};

export default ThrowConfettiSubmitted;
