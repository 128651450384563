import React from "react";
import FindGuestImage from "../DataHandling/FindGuestImage";

function Greeting({ data, error, onImageLoad }) {
  const { imageSrc, handleImageError } = FindGuestImage();
  let guestName = "You're invited!"; 
  let guestNameWithBreaks = guestName;

  //AOS Animations
  const aosOffset = "0";

  if (error) {
    console.log("Error fetching guest information: ", error.message);
    return null; // Optionally handle the error state here
  } else if (!data) {
    //API not responded yet, display nothing
    return null;
  } else {
    // If guests returned, override anonymous default with guest's name(s)
    guestName =
      data.numGuests === 1
        ? data.guest1Name.toUpperCase()
        : data.numGuests === 2
        ? `${data.guest1Name.toUpperCase()}\nAND ${data.guest2Name.toUpperCase()}`
        : (guestName = guestName.toUpperCase());

    guestNameWithBreaks = guestName.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div className="greeting">
      <h2 data-aos="fade-up" data-aos-offset={aosOffset} data-aos-delay="1000">{guestNameWithBreaks}</h2>
      <img
        src={imageSrc}
        alt="Michael and Laura"
        className="greeting-image"
        onError={handleImageError}
        onLoad={onImageLoad} //Notify main app when image has loaded
        data-aos="fade-right" data-aos-offset={aosOffset} data-aos-delay="500"
      />
      <p className="greeting-intro" data-aos="fade-up" data-aos-offset={aosOffset} data-aos-delay="1500">
        Together with their families, Laura and Michael request the pleasure of
        your company to help celebrate their marriage.
      </p>
    </div>
  );
}

export default Greeting;
