import React, { useState } from "react";
import confetti from "canvas-confetti";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [, setConfettiCount] = useState(0);

  const handleMenuToggle = () => {
    if (!menuOpen) {
      setMenuOpen(true);
      setTimeout(() => setMenuVisible(true), 10); // Delay to ensure CSS transition applies
    } else {
      setMenuVisible(false);
      setTimeout(() => setMenuOpen(false), 1000); // Delay matches the CSS transition duration
    }
  };

  const handleLinkClick = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      const headerHeight = document.querySelector(".header").offsetHeight;
      const elementPosition = section.offsetTop;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
    setMenuVisible(false);
  };

  const handleConfetti = () => {
    //Check if browser supports "OffscreenCanvas" - see here: https://www.tutorialspoint.com/how-to-check-the-offscreencanvas-is-supported-by-the-browser-or-not-in-javascript
    if (typeof OffscreenCanvas !== "undefined") {
      setConfettiCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount % 10 === 0) {
          // Huge confetti burst every 10th press
          try {
            confetti({
              particleCount: 2000,
              spread: 360,
              origin: { y: 0.25 },
              startVelocity: 100,
              ticks: 400,
            });
            confetti({
              particleCount: 300,
              spread: 360,
              startVelocity: 30,
              origin: { y: 0.25 },
              ticks: 400,
            });
            confetti({
              particleCount: 100,
              spread: 360,
              startVelocity: 10,
              origin: { y: 0.25 },
              ticks: 400,
            });
          } catch (e) {
            console.log("Confetti failed.", e);
          }
        } else {
          // Regular confetti burst
          try {
            confetti({
              particleCount: 150,
              spread: 360,
              startVelocity: 30,
              origin: { y: 0.25 },
            });
            confetti({
              particleCount: 50,
              spread: 360,
              startVelocity: 10,
              origin: { y: 0.25 },
              ticks: 400,
            });
          } catch (e) {
            console.log("Confetti failed.", e);
          }
        }
        return newCount;
      });
    }
  };

  return (
    <div>
      <div className="header">
        <div className="burger-menu" onClick={handleMenuToggle}>
          ☰
        </div>
        <div className="LM">L + M</div>
        <div className="confetti-emoji" onClick={handleConfetti}>
          🎉
        </div>
      </div>
      <div className={`fullscreen-menu ${menuVisible ? "visible" : ""}`}>
        <a href="#greeting" onClick={(e) => handleLinkClick(e, "greeting")}>
          Invitation
        </a>
        <a href="#details" onClick={(e) => handleLinkClick(e, "details")}>
          The details
        </a>

        <a href="#rsvp" onClick={(e) => handleLinkClick(e, "rsvp")}>
          RSVP
        </a>
        <a
          href="#print-invitation"
          onClick={(e) => handleLinkClick(e, "print-invitation")}
        >
          Print
        </a>
        <a
          href="#what-to-wear"
          onClick={(e) => handleLinkClick(e, "what-to-wear")}
        >
          What to wear
        </a>
        <a
          href="#additional-info"
          onClick={(e) => handleLinkClick(e, "additional-info")}
        >
          Everything else
        </a>
      </div>
    </div>
  );
}

export default Header;
