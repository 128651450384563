// fetchGuestData.js
import { useFetch } from "react-async";

const FetchGuestData = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const APIEndPoint = `${process.env.REACT_APP_API_ENDPOINT}/guests/${queryParams.get("guest")}`;

  const { data, error } = useFetch(APIEndPoint, {
    headers: { accept: "application/json" },
  });

  return { data, error };
};

export default FetchGuestData;