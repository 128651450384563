import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./App.css";
import FetchGuestData from "./DataHandling/FetchGuestData.js";
import Header from "./Components/Header";
import Greeting from "./Components/Greeting";
import Details from "./Components/Details";
import WhatToWear from "./Components/WhatToWear";
import RSVP from "./Components/RSVP";
import PrintInvitation from "./Components/PrintInvitation";
import AdditionalInfo from "./Components/AdditionalInfo";
import Footer from "./Components/Footer";

function App() {
  const { data, error } = FetchGuestData();
  const [guestData, setGuestData] = useState(null);
 
  //Only mark the page as loaded once the main guest image and the map image have loaded
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const handleMainImageLoad = () => {
    setMainImageLoaded(true);
  };
  const [mapImageLoaded, setMapImageLoaded] = useState(false);
  const handleMapImageLoad = () => {
    setMapImageLoaded(true);
  };

  useEffect(() => {
    if (data) {
      setGuestData(data);
    }

  //fade animations
    AOS.init({
      offset: 300,
      duration: 1500,
      delay: 100,
      once: true,
    }); 
  }, [data]);

  return (
    <div className="App">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
        @import
        url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
      </style>

      <Header />
      
      <div id="content" >
        <section id="greeting">
          <Greeting data={guestData} error={error} onImageLoad={handleMainImageLoad}/>
        </section>
        <section id="details" >
          <Details onImageLoad={handleMapImageLoad} pageHasLoaded={mainImageLoaded && mapImageLoaded}/>
        </section>
        <section id="rsvp" >
          <RSVP data={guestData} error={error} setData={setGuestData} />
        </section>
        <section id="print-invitation">
          <PrintInvitation data={guestData} />
        </section>
        <section id="what-to-wear">
          <WhatToWear />
        </section>
        <section id="additional-info">
          <AdditionalInfo />
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default App;
