import React, { useState, useEffect } from "react";
import GuestResponse from "./GuestResponse";
import ThrowConfettiSubmitted from "./Confetti";
import SubmitResponse from "../../DataHandling/SubmitResponse";

const RSVPForm = ({ data, setData }) => {
  const [showForm, setShowForm] = useState(false);
  const [showRsvpButton, setShowRsvpButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [guest1Name, setGuest1Name] = useState(data?.guest1Name || "");
  const [guest2Name, setGuest2Name] = useState(data?.guest2Name || "");
  const [guest1Response, setGuest1Response] = useState("");
  const [guest2Response, setGuest2Response] = useState("");
  const [guest1Dietary, setGuest1Dietary] = useState("");
  const [guest1Song, setGuest1Song] = useState("");
  const [guest1Message, setGuest1Message] = useState("");
  const [guest2Dietary, setGuest2Dietary] = useState("");
  const [guest2Song, setGuest2Song] = useState("");
  const [guest2Message, setGuest2Message] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showGuest2, setShowGuest2] = useState(false);

  useEffect(() => {
    if (data?.numGuests === 2 || showGuest2) {
      if (guest1Response === "accept" && guest2Response === "accept") {
        setIsButtonDisabled(
          guest1Dietary === "" ||
            guest1Song === "" ||
            guest2Dietary === "" ||
            guest2Song === "" ||
            (data.numGuests === 0 && (guest1Name === "" || guest2Name === ""))
        );
      } else if (guest1Response === "accept" && guest2Response === "decline") {
        setIsButtonDisabled(
          guest1Dietary === "" ||
            guest1Song === "" ||
            (data.numGuests === 0 && (guest1Name === "" || guest2Name === ""))
        );
      } else if (guest1Response === "decline" && guest2Response === "accept") {
        setIsButtonDisabled(
          guest2Dietary === "" ||
            guest2Song === "" ||
            (data.numGuests === 0 && (guest1Name === "" || guest2Name === ""))
        );
      } else if (guest1Response === "decline" && guest2Response === "decline") {
        setIsButtonDisabled(
          data.numGuests === 0 && (guest1Name === "" || guest2Name === "")
        );
      } else {
        setIsButtonDisabled(true);
      }
    } else if (data?.numGuests === 1 || !showGuest2) {
      if (guest1Response === "accept") {
        setIsButtonDisabled(
          guest1Dietary === "" ||
            guest1Song === "" ||
            (data.numGuests === 0 && guest1Name === "")
        );
      } else if (guest1Response === "decline") {
        setIsButtonDisabled(data.numGuests === 0 && guest1Name === "");
      } else {
        setIsButtonDisabled(true);
      }
    } else if (data?.numGuests === 0) {
      if (guest1Name === "") {
        setIsButtonDisabled(true);
      } else if (guest1Response === "accept") {
        setIsButtonDisabled(guest1Dietary === "" || guest1Song === "");
      } else if (guest1Response === "decline") {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [
    guest1Name,
    guest1Response,
    guest2Name,
    guest2Response,
    guest1Dietary,
    guest1Song,
    guest1Message,
    guest2Dietary,
    guest2Song,
    guest2Message,
    data?.numGuests,
    showGuest2,
  ]);

  const handleRSVPClick = () => {
    const button = document.querySelector("#rsvp-button");
    button.classList.add("fade-out");
    setShowForm(true);
    setTimeout(() => {
      setShowRsvpButton(false);
    }, 1500); // Delay form display by 1 second after slide-down starts
  };

  const scrollToRsvpSection = () => {
    const section = document.getElementById("rsvp");
    if (section) {
      const headerHeight = document.querySelector(".header").offsetHeight;
      const elementPosition = section.offsetTop;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleAddGuest2 = () => {
    setShowGuest2(true);
  };

  const handleRemoveGuest2 = () => {
    setShowGuest2(false);
    setGuest2Name("");
    setGuest2Response("");
    setGuest2Dietary("");
    setGuest2Song("");
    setGuest2Message("");
  };

  const handleSubmit = async () => {
    const requestBody = {
      inviteId: data.id,
      guest1: {
        name: data.numGuests === 0 ? guest1Name : data.guest1Name,
        isAttending: guest1Response === "accept",
        dietaryRequirements: guest1Dietary,
        favouriteSong: guest1Song,
        declinedMessage: guest1Message,
      },
    };

    if (data.numGuests === 2 || showGuest2) {
      requestBody.guest2 = {
        name: data.numGuests === 0 ? guest2Name : data.guest2Name,
        isAttending: guest2Response === "accept",
        dietaryRequirements: guest2Dietary,
        favouriteSong: guest2Song,
        declinedMessage: guest2Message,
      };
    }

    setLoading(true);
    const startTime = Date.now();

    try {
      await SubmitResponse(requestBody);
      const duration = Date.now() - startTime;
      const delay = Math.max(0, 1000 - duration); // Ensure at least 1 second delay

      setTimeout(() => {
        setLoading(false);
        setData({ ...data, hasResponded: true });
        scrollToRsvpSection();

        // Check if at least one guest accepts
        if (
          guest1Response === "accept" ||
          ((data.numGuests === 2 || showGuest2) && guest2Response === "accept")
        ) {
          ThrowConfettiSubmitted();
        }
      }, delay);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      {showRsvpButton && (
        <button id="rsvp-button" onClick={handleRSVPClick}>
          RSVP
        </button>
      )}
      {showForm && (
        <div className="rsvp-form fade-in">
          <GuestResponse
            guestId="guest1"
            guestName={data.numGuests === 0 ? guest1Name : data.guest1Name}
            setGuestName={setGuest1Name}
            response={guest1Response}
            setResponse={setGuest1Response}
            dietary={guest1Dietary}
            setDietary={setGuest1Dietary}
            song={guest1Song}
            setSong={setGuest1Song}
            message={guest1Message}
            setMessage={setGuest1Message}
            showGuestName={data.numGuests === 0}
          />
          {(data.numGuests === 2 || showGuest2) && (
            <div className="guest-2-div">
              <GuestResponse
                guestId="guest2"
                guestName={data.numGuests === 0 ? guest2Name : data.guest2Name}
                setGuestName={setGuest2Name}
                response={guest2Response}
                setResponse={setGuest2Response}
                dietary={guest2Dietary}
                setDietary={setGuest2Dietary}
                song={guest2Song}
                setSong={setGuest2Song}
                message={guest2Message}
                setMessage={setGuest2Message}
                showGuestName={data.numGuests === 0}
              />
            </div>
          )}
          <div className="guest-button-container">
            {!showGuest2 && data.numGuests === 0 && (
              <button onClick={handleAddGuest2} className="add-guest-button">
                +
              </button>
            )}
            {showGuest2 && data.numGuests === 0 && (
              <button
                onClick={handleRemoveGuest2}
                className="remove-guest-button"
              >
                -
              </button>
            )}
          </div>
          <button
            className="reply-now-button"
            onClick={handleSubmit}
            disabled={isButtonDisabled || loading}
          >
            {loading ? <div className="spinner"></div> : "Reply now"}
          </button>
        </div>
      )}
    </>
  );
};

export default RSVPForm;
