import { useState, useEffect } from 'react';

const FindGuestImage = (defaultImageName = 'michael-and-laura') => {
  const queryParams = new URLSearchParams(window.location.search);
  const guest = queryParams.get('guest') || defaultImageName;
  const initialImageSrc = `/photos/${guest}.png`;

  const [imageSrc, setImageSrc] = useState(initialImageSrc);
  const [error, setError] = useState(false);

  useEffect(() => {
    // When imageSrc changes, reset error state
    setError(false);
  }, [imageSrc]);

  const handleImageError = () => {
    if (!error) {
      console.warn(`Warning: The requested image ${imageSrc} failed to load. Falling back to default image, ${defaultImageName}.png.`);
      setImageSrc(`/photos/${defaultImageName}.png`);
      setError(true);
    }
  };

  return { imageSrc, handleImageError };
};

export default FindGuestImage;
