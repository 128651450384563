async function SubmitResponse(responseData) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/responses`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(responseData),
      }
    );

    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw new Error(result.message);
    }
  } catch (error) {
    throw new Error("Error submitting response: " + error.message);
  }
}

export default SubmitResponse;
