import React, { useState, useEffect } from "react";

const GuestResponse = ({
  guestName,
  setGuestName,
  response,
  setResponse,
  dietary,
  setDietary,
  song,
  setSong,
  message,
  setMessage,
  showDeclineMessage = true,
  showGuestName = false,
  guestId, // New prop to uniquely identify each guest
}) => {
  const [showAcceptQuestions, setShowAcceptQuestions] = useState(false);
  const [showDeclineQuestions, setShowDeclineQuestions] = useState(false);

  useEffect(() => {
    if (response === "accept") {
      setShowAcceptQuestions(true);
      setShowDeclineQuestions(false);
      setMessage(""); // Clear decline message when accepting
    } else if (response === "decline") {
      setShowAcceptQuestions(false);
      setShowDeclineQuestions(true);
      setDietary(""); // Clear dietary requirements and song when declining
      setSong("");
    }
  }, [response, setMessage, setDietary, setSong]);

  const handleAcceptChange = () => {
    setResponse("accept");
  };

  const handleDeclineChange = () => {
    setResponse("decline");
  };

  const adjustTextareaHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + 2}px`;
  };

  return (
    <>
      {!showGuestName && <h3>{guestName}</h3>}
      {showGuestName && (
        <div className="guest-name-container">
          <p
            htmlFor={`guest-name-${guestId}`}
            className="rsvp-anonymous-name-heading"
          >
            Name:
          </p>
          <br />
          <input
            type="text"
            id={`guest-name-${guestId}`}
            value={guestName}
            onChange={(e) => setGuestName(e.target.value)}
            className="rsvp-input-name"
          />
        </div>
      )}
      <p>
        <input
          type="radio"
          id={`accept-${guestId}`}
          name={`response-${guestId}`}
          value="accept"
          onChange={handleAcceptChange}
          checked={response === "accept"}
        />
        <label htmlFor={`accept-${guestId}`}>Would love to come</label>
      </p>
      <p>
        <input
          type="radio"
          id={`decline-${guestId}`}
          name={`response-${guestId}`}
          value="decline"
          onChange={handleDeclineChange}
          checked={response === "decline"}
        />
        <label htmlFor={`decline-${guestId}`}>Sorry, can't make it</label>
      </p>
      {showAcceptQuestions && (
        <>
          <p className="dietaryQuestion">
            Do you have any dietary requirements?
          </p>
          <textarea
            rows="1"
            maxLength="1000"
            onInput={(e) => {
              adjustTextareaHeight(e);
              setDietary(e.target.value);
            }}
            value={dietary}
            className="rsvp-input" // Apply the same class as other text fields
          />
          <p className="songQuestion">
            What song will get you on the dance floor?
          </p>
          <textarea
            rows="1"
            maxLength="1000"
            onInput={(e) => {
              adjustTextareaHeight(e);
              setSong(e.target.value);
            }}
            value={song}
            className="rsvp-input" // Apply the same class as other text fields
          />
        </>
      )}
      {showDeclineQuestions && showDeclineMessage && (
        <>
          <p className="devastatedMessage">
            Devastating! Do you have a message for the bride and groom?
          </p>
          <textarea
            rows="1"
            maxLength="1000"
            onInput={(e) => {
              adjustTextareaHeight(e);
              setMessage(e.target.value);
            }}
            value={message}
            className="rsvp-input" // Apply the same class as other text fields
          />
        </>
      )}
    </>
  );
};

export default GuestResponse;
